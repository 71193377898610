@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu:wght@400..700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Gulzar&display=swap"); */

body {
  margin: 0;
  font-family: "Poppins", "Tajawal";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll !important;
  font-size: 14px;
  text-align: unset !important;
}

*:not(.material-icons, .fa, [class^="fa"]) {
  font-family: "Poppins", "Tajawal" !important;
}

.MuiTypography-body1 {
  font-family: "Poppins" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html[lang="ur"] * {
  /* Change font-family for elements when lang attribute is set to "ur" */
  /* Specify your desired font-family */
  font-family: "Noto Nastaliq Urdu", serif !important;
  /* font-family: "Gulzar", serif !important; */
  font-optical-sizing: auto !important;
  font-style: normal;
}
