@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu:wght@400..700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amatic+SC&family=Gideon+Roman&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Gulzar&display=swap"); */

body {
  margin: 0;
  font-family: "Poppins", "Tajawal";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll !important;
  font-size: 14px;
  text-align: unset !important;
}

*:not(.material-icons, .fa, [class^="fa"]) {
  font-family: "Poppins", "Tajawal" !important;
}

.MuiTypography-body1 {
  font-family: "Poppins" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html[lang="ur"] * {
  /* Change font-family for elements when lang attribute is set to "ur" */
  /* Specify your desired font-family */
  font-family: "Noto Nastaliq Urdu", serif !important;
  /* font-family: "Gulzar", serif !important; */
  font-optical-sizing: auto !important;
  font-style: normal;
}

/* Font sizes */
.f-11 {
  font-size: 11px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-15 {
  font-size: 15px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-22 {
  font-size: 22px !important;
}
.f-24 {
  font-size: 24px !important;
}
.f-25 {
  font-size: 25px !important;
}
.f-30 {
  font-size: 30px !important;
}
.f-38 {
  font-size: 38px !important;
}
.f-40 {
  font-size: 40px !important;
}
/* nowrap */
.no-wrap {
  white-space: nowrap !important;
}
@media (max-width: 576px) {
  .f-40 {
    font-size: 24px !important;
  }
}
div.ant-typography {
  font-size: 15px;
}
/* End Font Sizes */

/* Font Weight */
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}

/* End Font Weight */
/* Spacings */
.space15 {
  margin-top: 15px;
}
.space21 {
  margin-top: 21px;
}
.space33 {
  margin-top: 33px;
}
.space37 {
  margin-top: 37px;
}
.space71 {
  margin-top: 71px;
}
.mobile-top-spacing {
  margin-top: 142px !important;
}
.spacing-bottom-25 {
  padding-bottom: 25px !important;
}
.study-overview-list li {
  margin-top: 5px;
  margin-bottom: 8px;
}
@media (max-width: 576px) {
  .assessment .space71 {
    margin-top: 30px;
  }
}
.p-l139-r42 > .w-100 {
  padding-right: 42px;
  padding-left: 139px;
}
/* End Spacing */

/* Display shortcuts */
.d-flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.d-flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.d-flex-end-start {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.d-flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.d-flex-end-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.d-flex-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.d-flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* End Display shortcuts */
/* Cursor Style */
.cursor-pointer {
  cursor: pointer;
}
/* End Cursor Style */
@media screen and (max-width: 800px) {
  .ant-row {
    margin: 0 !important;
  }
  .future {
    padding-right: 10px !important;
    padding-left: 10px;
  }

  .footer {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
main {
  overflow-x: hidden;
  width: 100%;
}
.auto {
  width: 100%;
  height: auto;
}

.makeStyles-line-36 {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-25 {
  margin-bottom: 25px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}

.py-md-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-md-7 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.bg-main a {
  color: #343379 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.text-main {
  color: #313060 !important;
}
.text-submain {
  color: #079751 !important;
}
.text-light {
  color: #fff !important;
}
.text-light-gray {
  color: #686874 !important;
}
.text-heading {
  color: #343379 !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid #aaa;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid #aaa;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;

  border-left: 60px solid #aaa;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 10px solid #aaa;
}
.ApplicationProcess {
  /* margin-top: 0 !important; */
  margin-top: -70px;
}
.ApplicationProcess .ant-tabs {
  background-color: white;
}
.card-container {
  background-color: #eeeeee;
  width: 100%;
  height: 67px;
  position: relative;
  bottom: -67px;
}
.ApplicationProcess p {
  margin: 0;
  margin-top: -5px !important;
}
.ApplicationProcess .ant-tabs-nav-wrap {
  margin: 0;
  color: #3a3976;
  font-weight: 500;
  height: 80px;
}
.ApplicationProcess > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}
.ApplicationProcess > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.ApplicationProcess > .ant-tabs-card .ant-tabs-tab,
[data-theme="compact"] .ApplicationProcess > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-collapse: collapse;
}
/* fixed margin/border (added  by-default by ant tab) */
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom
  > div
  > .ant-tabs-nav.ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom: 0;
}
/* ///////////////////////// */
.ApplicationProcess > .ant-tabs-card .ant-tabs-tab-active,
[data-theme="compact"]
  .ApplicationProcess
  > .ant-tabs-card
  .ant-tabs-tab-active {
  background: #089752;
  border-color: #089752;
  color: white;
  font-weight: 500;
}
/* tabs Text Center */
.ApplicationProcess .ant-tabs-tab-btn {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
[dir="rtl"] .ApplicationProcess .ant-tabs-tab-btn {
  position: relative;
  left: 0;
  transform: translateX(-50%);
}

[dir="rtl"] .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: end !important;
}

[dir="rtl"] .drawer-open .p-139 {
  padding-left: 60px;
}

.ApplicationProcess .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  font-weight: 500;
  margin-left: 0;
}
.ApplicationProcess .ant-tabs-tab.ant-tabs-tab > .ant-tabs-tab-btn:hover {
  color: #343379;
}
.ApplicationProcess .ant-tabs-tab.ant-tabs-tab.active .ant-tabs-tab-btn:hover {
  color: white !important;
}
.ApplicationProcess .ant-tabs-tab.ant-tabs-tab:hover {
  color: #343379;
}

.ApplicationProcess .ant-tabs-tab {
  min-width: 206px;
  height: 67px;
}

/*  */

.bg-main {
  background: #ffffffe3 0 0 no-repeat padding-box !important;
  padding-top: 40px;
  padding-bottom: 66px;
  margin-top: -1px;
  width: 100%;
}

span.ant-breadcrumb-link {
  color: #079751;
}

.MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ant-breadcrumb span {
  font-size: 12px;
}
.ApplicationProcess > .ant-tabs-card .ant-tabs-tab-active:after {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  bottom: -12px;
  width: 0;
  height: 0;
  content: "";
  border-left: 27px solid transparent;
  border-right: 27px solid transparent;
  border-top: 20px solid #089752;
}

/* */
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  transition: none;
}
/* responsive */

/* phones 600px and down */
@media only screen and (max-width: 600px) {
  .ant-tabs-tab {
    width: 100vw;
    text-align: center;
  }
}
/* ///////////////// Slick Carouse Info ///////////////// */

.slick-carousel-info {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 50vw;
}
.slick-carousel-info :is(h1, p) {
  color: white;
  text-align: center;
  font-weight: 700;
}
.carousel-content {
  background: transparent
    linear-gradient(270deg, #079751b8 0, #07070782 49%, #343379d1 100%) 0 0
    no-repeat padding-box;
}
.next-arrow-carousel {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}
.next-arrow-carousel .carousel-arrow i {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  opacity: 1;
}
.slick-arrow .slick-prev {
  right: 120px !important;
}
.change-direction {
  transform: rotate(183deg) !important;
  top: 20% !important;
  left: 20% !important;
}
/* responsive */

/* phones 600px and down */
@media only screen and (max-width: 600px) {
  :is(.slick-next, .slick-prev) {
    visibility: hidden;
  }
  .slick-carousel-info {
    width: 80vw;
  }

  .slick-carousel-info :is(h1, p) {
    font-weight: 600;
    font-size: 22px;
  }
}
/* Tablet  (laptops/desktops, max 992px ) */
@media only screen and (max-width: 992px) {
  :is(.slick-next, .slick-prev) {
    visibility: hidden;
  }
  .slick-carousel-info {
    width: 80vw;
  }
}

/* Tabs Pane */

.ApplicationProcess .Tabs {
  background-color: #079751;
}

/* ////////////////////////Home  Tabs //////////////////////// */
.home-bg-light {
  background-color: rgba(255, 255, 255, 0.94);
}
.container-tabs {
  margin: 0;
  padding: 0;
}
.home-tab-content p {
  font-weight: 500;
  text-align: center;
}
.home-tab-content Button {
  border: #343379 solid 1px;
  color: #343379;
  font-weight: 600;
  font-size: 0.8rem;
  margin: 0 10px 0 10px;
  text-align: center;
  border-radius: 0;
  width: 172px;
  min-height: 59px;
}
.home-tab-content Button:hover {
  color: #343379;
}
.home-tab-content Button:focus {
  box-shadow: none;
}

/* home about us */

.home-about-us {
  text-align: center;
  font-weight: 600;
}
.drawer-close .home-about-us {
  padding-right: 139px;
}
.drawer-open .home-about-us {
  padding-right: 60px;
}

.home-about-us h2 {
  color: black;
  margin-bottom: 20px;
}
.home-about-us a {
  color: #079751;
}
.about-news-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.home-about-us a:hover {
  color: #079751;
  text-decoration: none;
}

/* home-message */

.home-message-container {
  background-color: #313060;
  color: white;
  min-width: 80%;
}
.home-message:is(.card-body .card-title, h2) {
  color: white;
  margin-bottom: 20px;
}
.home-message-container .card {
  color: white;
}
.home-message-container .card-body span .half-border {
  border-bottom: 2px solid white;
  width: 60%;
}
.home-message-card img {
  float: right;
}
.home-message-card {
  width: 75%;
}

/* responsive */

/* phones 600px and down */
@media only screen and (max-width: 600px) {
  .home-message-card img {
    float: none;
    position: relative;
  }
  [dir="ltr"] .home-message-card img {
    left: 50%;
    transform: translateX(-50%);
  }
  .home-message-card {
    width: 100%;
  }
}
/* Tablet  (laptops/desktops, max 992px ) */
@media only screen and (max-width: 992px) {
  .home-message-card img {
    float: none;
    position: relative;
  }
  [dir="ltr"] .home-message-card img {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* ///////////////////////// home news ///////////////////////// */
.newCard {
  margin-top: 42px;
}
.news-heading a {
  color: #079751;
}
.news-heading a,
.news-heading a:hover,
.news-heading a:active {
  color: #079751;
  text-decoration: none;
}
.news-heading {
  display: flex;
  align-items: center;
}

.news-heading li:nth-child(2) span {
  border-left: 3px solid #079751;
  padding: 0 8px 0 15px;
  vertical-align: middle;
}
:is(.news-card) .card {
  border: 0;
  box-shadow: 5px 3px 9px 2px rgba(50, 49, 119, 0.2);
  -webkit-box-shadow: 5px 3px 9px 2px rgba(50, 49, 119, 0.2);
  -moz-box-shadow: 5px 3px 9px 2px rgba(50, 49, 119, 0.2);
  background-image: url(/static/media/news1.5f50923c.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 370px;
}
.simple-card-border {
  border-top: 1px solid #e9e9e9;
}

/* styling for card body */
.card-news {
  position: absolute;
  background-color: rgba(49, 48, 96, 0.9);
  color: white;
}

.news-card .card .card-body {
  width: 100%;
  bottom: 0;
}
.news-card {
  width: 40% !important;
}
.vertical-news-card {
  width: 60% !important;
}
@media (max-width: 576px) {
  .news-card {
    width: 100% !important;
  }

  .vertical-news-card {
    width: 100% !important;
  }
}
.news-card .card {
  width: 230px;
}
/* vertical news */

.vertical-news-card .card-news::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20.2px;
  width: 0;
  height: 0;
  content: "";
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 20px solid rgba(49, 48, 96, 0.9);
}

:is(.news-card, .vertical-news-card) .card .card-body .card-title {
  color: #079751;
}

.vertical-news-card .card .card-body {
  width: 70%;
  height: 100%;
  left: 0;
}

.vertical-news-card .card {
  background-image: url(/static/media/news1.5f50923c.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 177px;
}

:is(.vertical-news-card) .card {
  border: 0;
  box-shadow: 5px 3px 9px 2px rgba(50, 49, 119, 0.2);
  -webkit-box-shadow: 5px 3px 9px 2px rgba(50, 49, 119, 0.2);
  -moz-box-shadow: 5px 3px 9px 2px rgba(50, 49, 119, 0.2);
}
.width-100 {
  width: 100%;
}
.w-sm-v-item {
  width: 50%;
}

/* responsive */

/* phones 600px and down */
@media only screen and (max-width: 600px) {
  .w-sm-v-item {
    width: 100%;
  }
  :is(.news-card) .card {
    height: 50vh;
    margin-bottom: 10px;
    width: 100%;
  }
  :is(.vertical-news-card) .card {
    height: 32vh;
    margin-bottom: 10px;
  }
}

/* ///////////////////////////// home events ///////////////////////////// */

.events .card-image {
  background-color: #089752;
  width: 100%;
  height: 170px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.events .card-body {
  font-weight: 600;
}
/* date  vertical card */
.date-vertical-card {
  background-color: #313060;
  color: white;
}
.date-vertical-card i {
  font-size: 2.5rem;
  margin-top: 40px;
}
.date-vertical-card p {
  color: white;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.events .card-body p:nth-child(1) {
  border-left: 3px solid #079751;
  padding: 0 8px 0 15px;
  vertical-align: middle;
}
/* responsive */

/* end events */

.ant-typography {
  color: black;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.Colleges .ant-card-meta-description {
  color: black !important;
}
.SyllabusCard {
  box-shadow: 0 3px 6px #00000008;
  border: 1px solid #c9c9c9;
}
.SyllabusCard hr {
  margin-top: 9px;
  margin-bottom: 9px;
}
.SyllabusCard .ant-typography {
  margin-bottom: 0 !important;
}

.sub-nav li {
  cursor: pointer;
}
.ant-btn-primary {
  background-color: #343379 !important;
  border-color: #343379 !important;
}
.course-catelogue-header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 169px;
}
.online-free-course thead th {
  background: #151426 !important;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.online-free-course tbody tr {
  border-bottom: 1px solid #c7c7c7;
}
.online-free-course .ant-table-cell a {
  color: #079751 !important;
  text-decoration: none;
}
/* Events */
.date-vertical-card-container {
  box-shadow: 0 3px 6px #0000001a !important;
  border: 1px solid #d9d9d9 !important;
}

/* courses */
.courses-fees-cards {
  padding-left: 22px;
}
.courses-fees-card {
  width: 100%;
  padding: 0 2px 0 7px !important;
}
@media (max-width: 576px) {
  .courses-fees-card {
    width: 100%;
    padding: 0 !important;
  }
  .courses-fees-cards {
    padding-left: 0;
  }
}
.course-fees-content {
  width: 100% !important;
  height: 100% !important;
  background-color: white;
  border: 1px solid #d4d2d2;
}
/* Accreditation */
.future {
  background-color: transparent !important;
}

/* ////////////////////// Footer ////////////////////// */
.footer {
  background: #070711;
  color: white;
  min-height: 243px;
  /*padding-left: 139px;*/
}
.drawer-close .footer {
  padding-right: 139px;
}
.drawer-open .footer {
  padding-right: 60px;
}

.footer .ant-divider {
  border-color: white;
}
.footer .ant-divider-vertical {
  height: 17px;
}

.footer-item-content {
  color: #d3d1e5;
}
.footer-item-heading {
  width: 70px;
}
/* responsive */
@media (max-width: 576px) {
  .footer-item-heading {
    width: 100%;
  }
  .footer .ant-divider-vertical {
    display: none;
  }
  .footer-item-heading ul li:first-child span {
    padding-right: 40px;
    border-bottom: 1px solid white;
  }
  .footer-item-content ul li {
    padding-right: 20px;
  }
  .footer-list-item-container[style] {
    height: 70px !important;
  }
}

/* /////////////////////// Vertical Stepper - steps /////////////////////// */

.vertical-stepper {
  cursor: pointer;
}
.dotted {
  position: relative;
  margin: 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.circle {
  position: relative;
  text-align: center;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  border: 1px solid rgba(187, 185, 185, 0.5);
  z-index: 999;
  color: #282c34;
  font-weight: 600;
  transition: all ease-out 0.2s;
}

.circle-active {
  background-color: #079751;
  color: white;
  border: 2px solid white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  width: 50px;
  height: 50px;
}
.circle-value {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 100%;
}
.dots:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  height: inherit;
  border-left: 2px dotted #079751;
}

.vertical-stepper h6 {
  color: #079751;
  text-decoration: underline;
  height: 32px;
}
.vertical-stepper .content {
  padding-bottom: 15px;
}
.content-active :is(h6, small) {
  color: black;
  text-decoration: none;
  font-weight: 600;
}

/* Responsive w-100 padding-right fixed*/
.p-l139-r42 > .w-100 {
  padding-right: 139px;
}
/* .drawer-close .p-l139-r42 > .w-100 { */
/* padding-right: 139px; */
/* } */
.drawer-open .p-l139-r42 > .w-100 {
  padding-right: 60px;
}
@media only screen and (max-width: 576px) {
  /* .drawer-close .p-l139-r42 > .w-100 { */
  /* padding-right: 16px; */
  /* } */
}

/* phones/tablet 600px and down */
@media only screen and (max-width: 992px) {
  .p-l139-r42 > .w-100 {
    padding: 0;
  }
  .w-responsive-10 {
    padding: 0 10px;
  }
}
.about-us-faculties .ant-card-body {
  padding-left: 0 !important;
}

/* .about-faculties-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */
/* MuiBix Responsive (about-us page)*/

.about-us-contact .MuiBox-root-288 {
  padding-left: 0;
}
.about-us-contact .MuiBox-root-290 {
  padding-left: 0;
}
@media only screen and (max-width: 576px) {
  .about-us-vision .MuiBox-root {
    padding-top: 25px;
  }
  .about-us-faculties .ant-card-body {
    padding: 0;
  }
  .about-us-contact .MuiBox-root {
    padding: 0;
  }
}

.about-future-img {
  width: 199px;
  height: 159px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.director-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* margin-left: -10px; */
}

.director-title-line {
  width: 181px;
  height: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #343379;
}

.mt-20 {
  margin-top: 20px !important;
}
.mb-06 {
  margin-bottom: 6px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}

@media only screen and (max-width: 992px) {
  .about-future-img {
    width: 100%;
    height: 159px;
  }
}

/* Row XS width issue fixed  (about-us page) */
@media only screen and (max-width: 576px) {
  .row-vision-page {
    width: 50%;
  }
}

.MuiButtonBase-root,
.MuiIconButton-root {
  padding: 1000px;
}
/* ///////////// appbar ///////////// */
.MuiPaper-elevation1 {
  box-shadow: 0 3px 6px #00000029;
}

/* ////////Drawer Close  ////////////// */
.drawer-close-phone {
  position: absolute;
  right: 0;
  padding: 35px;
  display: none;
}
.drawer-close-phone .close-icon {
  font-size: 30px;
  color: white;
}

/* select language drawer  */

.select-language-drawer {
  display: none;
  margin-top: 30px;
}

@media (max-width: 576px) {
  .drawer-close-phone,
  .select-language-drawer {
    display: block;
  }
}

.f-drawer-14 {
  font-size: 14px;
  transition: all 0.2s ease-in;
}
.fw-drawer-400 {
  font-weight: 400;
}
@media (max-width: 1024px) {
  .f-drawer-14 {
    font-size: 16px !important;
  }
}
.drawer-container .MuiListItem-gutters {
  /* padding-left: 0 !important; */
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.child-item-hover:hover {
  color: white;
}

/* ////////////// courses  ////////////// */

.courses-includes:after {
  position: absolute;
  left: 50%;
  top: -13px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  content: "";
  border-left: 32px solid transparent;
  border-right: 32px solid transparent;
  box-shadow: 0 3px 6px #0000000f;
  /* border-bottom: 20px solid #ffffff; */
  z-index: -1;
}
.triangle-white .courses-includes:after {
  border-bottom: 20px solid #fff;
}
.triangle-blue .courses-includes:after {
  border-bottom: 20px solid #313060;
}
.triangle-green .courses-includes:after {
  border-bottom: 20px solid #079751;
}
.triangle-orange .courses-includes:after {
  border-bottom: 20px solid #f57506;
}

/* nav collapse list item */
.nav-collapse-list-items {
  background-color: #1b1b47;
}

.homepage-Accordion-styled-tabs .MuiAccordionSummary-content {
  display: flex;
  justify-content: center;
}

/* Left pane Accordion Styled */

.left-tab-accordion-styled {
  background-color: #f3f3f3;
  width: 100%;
}
.left-tab-accordion-styled .heading {
  color: #00984f;
  padding: 0;
  border-bottom: #d4d4d4 1px solid;
  text-align: center;
}
.left-tab-accordion-styled .content {
  padding: 12px 0 4px 0;
  font-size: 1rem;
  display: block;
  text-align: center;
  cursor: pointer;
  color: #00984f;
}
.left-tab-accordion-styled .content p {
  display: inline-block;
  text-align: left;
}
.left-tab-accordion-styled .content:not(:last-child) {
  border-bottom: #d4d4d4 1px solid;
}
/* .left-tab-accordion-styled .contentBorder {
  border-bottom: #d4d4d4 1px solid;
} */

.left-tab-accordion-styled .hideContent {
  display: none;
}
.contentArrowDown {
  display: inline-block;
}
/* //////////////////////// not found ////////////////////////  */

.not-found {
  overflow: hidden;
}

.not-found img {
  width: 100% !important;
  height: 100vh !important;
  padding-bottom: 100px;
}
.not-found-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  width: 100%;
  padding: 10px;
}

.not-found-content h1 {
  font-weight: 600;
}
.not-found-content p {
  color: #6e6e6e;
  font-size: 1.1rem;
}
.not-found .go-to-button {
  background-color: #343379;
  color: white;
  padding: 16px 60px;
}

@media (max-width: 1400px) {
  .not-found img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 120%;
    padding-bottom: 150px;
  }
}
@media (max-width: 1200px) {
  .not-found img {
    padding-bottom: 250px;
  }
  .not-found-content {
    transform: translate(-50%, -25%);
  }
}
@media (max-width: 800px) {
  .not-found img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 250px;
    width: 200% !important;
  }
  .not-found-content {
    transform: translate(-50%, -25%);
  }
}
/* Loader */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
}
.loader {
  z-index: 99999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* ////////////////// admission deadline ////////////////// */

/* hr dotted admission details */
.admission-details hr {
  clear: both;
  display: block;
  width: 100%;
  border-top: dashed #079751;
  height: 1px;
  border-width: 2px;
}
@media (max-width: 576px) {
  .admission-details {
    flex-wrap: wrap;
    text-align: center;
  }
  .admission-details hr {
    border: 0;
    clear: both;
    display: block;
    border-left: 2px dashed #079751;
    height: 50px;
    margin: 0;
    position: relative;
    left: 50%;
  }
}
.w-nowrap {
  white-space: nowrap;
}

/* /////////////// course categories //////////// */
.course-categories-list-item {
  color: black !important;
}
.course-categories-list-item:hover {
  background-color: #f57506;
  color: white !important;
  cursor: pointer;
}

.course-details-content p {
  font-size: 15px;
}
.course-details-content h1 {
  font-size: 20px;
}
.drawer-close .p-139 {
  padding-left: 139px;
  padding-right: 139px;
}
.drawer-open .p-139 {
  padding-left: 139px;
  padding-right: 60px;
}

@media (max-width: 1024px) {
  .drawer-open .p-139 {
    padding-left: 80px;
  }
}

@media (max-width: 992px) {
  .drawer-close .p-139 {
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media (max-width: 800px) {
  .drawer-close .p-139 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .drawer-open .p-139 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media (max-width: 800px) {
  .m-padding-0 {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

/* ///////////////////// select language ///////////////////// */

.select-language a {
  box-shadow: none !important;
  color: #343379;
}

.select-language-content a {
  color: black;
}
.select-language .dropdown-menu {
  border: transparent;
  box-shadow: 0 3px 6px #00000029;
  min-width: 76px !important;
  border-radius: 0;
}
.select-language .dropdown-menu:active {
  background-color: unset;
}
.select-language .dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: transparent;
}

.select-language .dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  background-color: transparent;
}
.select-language .dropdown a:hover {
  color: #343379 !important;
}
.select-language-content li {
  box-sizing: border-box;
  margin-left: 10px;
  margin-right: 10px;
}
.select-language-content li {
  border-bottom: 1px solid #e8e8e8;
}
.select-language-content li:last-child {
  border-bottom: transparent;
}

.select-language {
  vertical-align: middle;
}
.select-language .dropdown-toggle {
  color: #343379 !important;
}
.select-language span,
.select-language span:hover {
  color: #343379 !important;
}
/* responsiveness */
@media (max-width: 576px) {
  .select-language span,
  .select-language span:hover {
    box-shadow: none !important;
    color: white !important;
  }
  .select-language .dropdown-toggle {
    color: transparent;
  }
  .select-language .dropdown a:hover {
    color: white;
  }
  .select-language .dropdown-item:hover,
  .dropdown-item:focus {
    color: black;
    text-decoration: none;
    background-color: transparent;
  }
}

/* Accordion styled tabs */
.MuiAccordionSummary-content {
  flex-grow: 0 !important;
}

/* future div */
.pl-295 {
  padding-left: 30% !important;
}
@media (max-width: 768px) {
  .pl-295 {
    padding-left: 24px !important;
  }
}

/* news cards */
.px-31 {
  padding-left: 31px !important;
  padding-right: 31px !important;
}
@media (max-width: 576px) {
  .px-31 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 576px) {
  .f-xs-20 {
    font-size: 15px !important;
  }
}

/* courses apply now */

/* admission apply now */
/* courses apply now */

.apply-now-responsive {
  display: none;
}
@media (max-width: 576px) {
  .apply-now-responsive {
    display: block;
    padding-left: 22px;
    padding-right: 22px;
  }
}

/* syllabus px-12 */

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
@media (max-width: 576px) {
  .px-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* why kiu */
@media (max-width: 576px) {
  .why-kiu-benefits .ant-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.slick-next {
  right: 60px;
}
.slick-prev {
  left: 60px;
}
li[class^='makestyles-']
/* RTL styles */
[dir="rtl"] .ApplicationProcess .ant-tabs-tab-btn {
  position: relative;
  left: 0;
  transform: translateX(-50%);
}
[dir="rtl"] body {
  text-align: right !important;
}
[dir="rtl"] .slick-next {
  left: 60px !important;
}
[dir="rtl"] .slick-prev {
  right: 60px !important;
}
[dir="rtl"] .news-heading li:nth-child(2) span {
  border-left: none;
  border-right: 3px solid #079751;
  padding: 0 8px 0 15px;
  margin-right: 5px;
  vertical-align: middle;
}
[dir="rtl"] .events .card-body p:nth-child(1) {
  border-left: none;
  border-right: 3px solid #079751;
  margin-right: 5px;
}
[dir="rtl"] .pl-295 {
  padding-left: 0 !important;
  padding-right: 30% !important;
}
[dir="rtl"] .mobile-float-left {
  float: left;
}
@media (max-width: 768px) {
  [dir="rtl"] .pl-295 {
    padding-left: 24px !important;
  }
  .future {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}
/* End RTL styles */

.bg-default {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.ql-editor {
  text-align: inherit !important;
  height: unset !important;
  padding: 12px 0 !important;
}

.texty > span {
  color: white;
}
.tagline {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: white;
  font-family: "Amatic SC", cursive !important;
  animation-name: taganimation;
  animation-duration: 2s;
}
@keyframes taganimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.7;
  }
  80% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

/*Gap Classes*/
.gap-100-px {
  grid-gap: 100px;
  gap: 100px;
}

.testimonial-slider {
  padding: 80px 0 84px !important;
}

.testimonial-slider .testimonial-profile {
  width: 60px;
  height: 60px;
  object-fit: fill;
  border-radius: 50%;
}

.testimonial-slider .media-body {
  margin-top: 10px;
}

.testimonial-slider .slick-dots {
  bottom: -46px !important;
}

.testimonial-slider .slick-dots li {
  margin: 0 2px;
  width: 12px !important;
  height: 12px !important;
}

.testimonial-slider .slick-dots li button {
  width: 12px !important;
  height: 12px !important;
}

.testimonial-slider .slick-dots li button::before {
  color: #d3d1e5 !important;
  width: 12px !important;
  height: 12px !important;
}

.testimonial-slider .slick-dots .slick-active button::before {
  color: #343379 !important;
  width: 12px !important;
  height: 12px !important;
}

.teaching-tab-content .ql-editor p {
  text-align: start;
  line-height: normal;
}

.institution-evidence-tab button {
  border: #343379 solid 1px;
  color: #343379;
  font-weight: 600;
  font-size: 0.8rem;
  margin: 0 10px 0 10px;
  text-align: center;
  border-radius: 0;
  width: 250px;
  min-height: 59px;
}
.institution-evidence-tab button:hover {
  color: #343379;
}
.institution-evidence-tab button:focus {
  box-shadow: none;
}

.bg-main-different {
  background: #ffffffe3 0 0 no-repeat padding-box !important;
  margin-top: -1px;
  width: 100%;
}

/* institution and evidence diffrent page css */
.institution-evidence-page .hero-section {
  background: rgba(50, 49, 119, 0.97);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  color: white;
  text-align: center;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.institution-evidence-page .institution-evidence-content {
  padding: 120px 80px 16px;
}

.institution-evidence-page .institution-evidence-content div {
  margin-bottom: 60px;
}

.institution-evidence-page .institution-evidence-content button {
  border: #343379 solid 1px;
  color: #343379;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  border-radius: 0;
  width: 100%;
  min-height: 59px;
}

/* mobile phones */
@media only screen and (max-width: 600px) {
  .institution-evidence-page .institution-evidence-content {
    padding: 74px 16px;
  }

  .institution-evidence-page .institution-evidence-content div:last-child {
    margin-bottom: 0;
  }
}

/* tablets */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .institution-evidence-page .institution-evidence-content {
    padding: 80px 40px 16px;
  }

  .institution-evidence-page .institution-evidence-content div:last-child {
    margin-bottom: 0;
  }
}

.w-40-per {
  width: 40% !important;
}

.h-48-per {
  width: 48% !important;
}

/* go back css */
.back-button {
  position: absolute;
  right: 40px;
  left: 40px;
  z-index: 9999;
  margin-top: 30px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.back-button a {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.back-button a:hover {
  cursor: pointer;
  color: #fff;
}

[dir="ltr"] .back-button .fa {
  transform: rotate(180deg);
}

